require('./js/_custom-vh.js')
import barba from '@barba/core'
import { hide, show, toggle } from 'slidetoggle';

document.addEventListener('DOMContentLoaded', function() {

	const mainMenuButton = document.getElementById('main-nav-button')
	mainMenuButton.addEventListener('click',() => {
		document.body.classList.toggle('menu_out')
	})
	
	const scroll_top = document.getElementById('scroll_top')
	document.addEventListener('scroll', e => {
	  window.scrollY > window.innerHeight
	  ? scroll_top.style.transform = 'translateY(0px)'
	  : scroll_top.style.transform = 'translateY(3rem)'
	});

	scroll_top.addEventListener('click',() => {
		window.scrollTo({ top: 0,   behavior: 'smooth'})					
	})

	function snap_containers() {
		document.querySelectorAll('.snappoint_clicker').forEach((snappoint) => {
	    	let next_snappoint;
	    	let prev_snappoint;

	    	function find_next_snappoint(){
				let placeholder = snappoint.nextElementSibling;

				while (placeholder) {
				  if (placeholder.classList.contains('snappoint')) {
				    next_snappoint = placeholder;
				    break;
				  }
				  placeholder = placeholder.nextElementSibling;
				}
			}

	    	function find_prev_snappoint(){
				let placeholder = snappoint.previousElementSibling;
				while (placeholder) {
				  if (placeholder.classList.contains('snappoint')) {
				    prev_snappoint = placeholder;
				    break;
				  }
				  placeholder = placeholder.previousElementSibling;
				}
			}

	    	snappoint.addEventListener('mouseenter',() => {
	    		find_prev_snappoint();
	    		find_next_snappoint();
	    	})
	    	snappoint.addEventListener('mouseleave',() => {
	    		main.dataset.cursorstyle = "";
	    	})
	    	
	    	snappoint.addEventListener('mousemove', function(e){
	    		//console.log(snappoint.getBoundingClientRect().height)
	    		let cursor_style;
	    		e.clientY < window.innerHeight / 2
	    		? cursor_style = 'n-resize'
	    		: cursor_style = 's-resize';

	    		const main = document.getElementById('main');
	    		if(cursor_style == 'n-resize' && prev_snappoint || cursor_style == 's-resize' && next_snappoint){
	    			main.dataset.cursorstyle = cursor_style
	    		} else{
	    			main.dataset.cursorstyle = ""
	    		}

	    	})

	    	snappoint.addEventListener('click',(e) => {
					if(e.target instanceof HTMLAnchorElement == false && window.matchMedia( '(min-width: 1000px) and (pointer:fine)' ).matches){
						if(e.clientY < window.innerHeight / 2){
							if(prev_snappoint) prev_snappoint.scrollIntoView({behavior: 'smooth'})
						} else{
							if(next_snappoint) next_snappoint.scrollIntoView({behavior: 'smooth'})
						}
					}
				
	    	});
	    });
	}
	
	barba.init({
		timeout: 3000,
		//cacheIgnore: true,
		sync: true,
		transitions: [{
			async once() {
				setTimeout(()=>document.getElementById('preloader').style.display = 'none', 2600);
			},
			async enter() {
				//document.body.classList.remove('blur')
				window.scrollTo({ top: 0 })
			},
			async leave() {
				//const done = this.async()
				//document.body.classList.add('blur')
				mainMenuButton.classList.remove('out')
				//setTimeout(() => done(), 500)
			}
		}],
		views: [
			{
				namespace: 'home',
				beforeEnter(){
					//Dyamic Import Flickity
					import('flickity')
					  .then((Flickity) => {
					    import('flickity-fade').then(() => {
					    	var elem = document.querySelector('.main-carousel');
							var flkty = new Flickity( elem, {
							  // options
							  cellAlign: 'left',
							  contain: true,
							  pageDots: false,
							  prevNextButtons: false,
							  autoPlay: true,
							  wrapAround: true,
							  pauseAutoPlayOnHover: false,
							  fade: true,
							  draggable: false,
							  autoPlay: 7000
							});
							elem.addEventListener('click',() => {
								flkty.next();
							})

							function update_ui_color(){
								const selectedSlide = document.querySelector('.is-selected');

								if (selectedSlide) {
								    const uiFarbe = selectedSlide.dataset.uiFarbe;
								    
								    uiFarbe
								    ? document.body.style.setProperty('--home-ui-color', uiFarbe)
								    :console.error('Data-Property "data-ui-farbe" nicht gefunden!')
								   
								} else {
								    console.error('Kein Element mit der Klasse "is-selected" gefunden!');
								}
							}

							update_ui_color();
							
							flkty.on( 'select', ()=>{
								update_ui_color();
							});

					    }).catch(error => console.log(error));
					}).catch(error => console.log(error));
				},
				afterEnter() {

				},
				afterLeave(){
				},
			},
			{
				namespace: 'page_architektur',
				beforeEnter(){
					let architekturList;
					import('list.js')
					  .then((List) => {
						architekturList = new List('architektur-list',
						{
						    valueNames: [{ data: ['id'] },'projekt', 'ort', 'typus', 'zeitraum' ]
						});

						if(localStorage.getItem('sort') && localStorage.getItem('sort_dir')){
							architekturList.sort(localStorage.getItem('sort'), { order: localStorage.getItem('sort_dir') });
						}

					}).catch(error => console.log(error));
					
					document.getElementById('button_listview').addEventListener('click',() => {
						document.body.classList.toggle('listview')
						architekturList.sort('id', { order: "asc" }); // Liste beim schließen der Listview immer wieder auf Ausgangsposition zurück sortieren.
						localStorage.clear();

						document.body.classList.contains('listview')
						? localStorage.setItem('listview', 'true')
						: localStorage.setItem('listview', 'false')
					})
					
				},
				afterEnter() {

					document.querySelectorAll('.sort').forEach((sort_button) => {
						sort_button.addEventListener('click',(e) => {
							localStorage.setItem('sort', e.target.dataset.sort)
							sort_button.classList.contains('desc')
							? localStorage.setItem('sort_dir', 'desc')
							: localStorage.setItem('sort_dir', 'asc')
						})
					})

					if(localStorage.getItem('listview') == 'true'){
						document.body.classList.add('listview')
					}

					// Hintergrundbild Listview austauschen.
					document.querySelectorAll('article').forEach((article) => {
						article.addEventListener('mouseover',(e) => {
							const bg_img = e.currentTarget.dataset.listview_bg.trim();
							document.getElementById('listview_bg').style.backgroundImage = `url('${bg_img}')`
							document.getElementById('listview_bg').style.opacity = 1
						})
						article.addEventListener('mouseout',() => {
							document.getElementById('listview_bg').style.opacity = 0
						})
					})

				},
				afterLeave(){
				},
			},
			{
				namespace: 'projekt_single',
				beforeEnter(){
					//Nicht vergessen: Über Barba.js vor / nach dieser Page body auf overflow:hidden//scroll zu setzen!!
					import('smoothscroll-polyfill').then(smoothscroll => smoothscroll.polyfill()).catch(err => {console.error(err); reject()})
				},
				afterEnter() { 
					snap_containers();

					document.querySelectorAll('.toggler').forEach((toggler) => {
						toggler.addEventListener('click',() => {
							//Toggle ausführen...
							toggler.classList.toggle('toggler_open')
							toggle(toggler.previousElementSibling, {
								miliseconds: 500,
								transitionFunction: 'cubic-bezier(0.83, 0, 0.17, 1)',
								elementDisplayStyle: 'grid' 
							})
						
						})
					})
				},
				afterLeave(){
				},
			},
			{
				namespace: 'page',
				beforeEnter(){
					//Nicht vergessen: Über Barba.js vor / nach dieser Page body auf overflow:hidden//scroll zu setzen!!
					import('smoothscroll-polyfill').then(smoothscroll => smoothscroll.polyfill()).catch(err => {console.error(err); reject()})
				},
				afterEnter() { 
					snap_containers();
				},
				afterLeave(){
				},
			},
			{
				namespace: 'page_kontakt',
				beforeEnter(){
					
				},
				afterEnter() {
				},
				afterLeave(){
				},
			},
			{
				namespace: 'page_news',
				beforeEnter(){
				},
				afterEnter() {
						
					let queryParams = new URLSearchParams(window.location.search);
					document.querySelectorAll('.toggler').forEach((toggler) => {
						toggler.addEventListener('click',() => {
							
							//alle geöffneten Toggler schließen...
							document.querySelectorAll('.toggler_open').forEach((toggler_open) => {
								if(toggler_open.dataset.id !== toggler.dataset.id){
									hide(toggler_open.nextElementSibling, { miliseconds: 200 })
									toggler_open.classList.remove('toggler_open')
								}
							})
							let news_base_url
							window.location.href.indexOf("/en/") > -1
							? news_base_url = '/en/news/'
							: news_base_url = '/news/'

							//URL query verändern...
							if (!toggler.classList.contains('toggler_open')) {
								queryParams.set("news_id", toggler.dataset.id);
								history.replaceState(null, null, `${news_base_url}?${queryParams.toString()}`);
							} else{
								history.replaceState(null, null, news_base_url);
							}

							//Toggle ausführen...
							toggler.classList.toggle('toggler_open')
							toggle(toggler.nextElementSibling, {
								miliseconds: 500,
								transitionFunction: 'cubic-bezier(0.83, 0, 0.17, 1)',
								elementDisplayStyle: 'grid' 
							})
						
						})
					})
				},
				afterLeave(){
				},
			}
		]
	})
	barba.hooks.afterLeave(() => {
	 	document.body.classList.remove('menu_out')
	});
	barba.hooks.enter((data) => {
  		// Update body-classes
		let regexp = /\<body.*\sclass=["'](.+?)["'].*\>/gi,
			match = regexp.exec(data.next.html);
			if (!match || !match[1]) {
			// If no body class, remove it
		    document.body.setAttribute("class", "");
		} else {
			// Set the new body class
			document.body.setAttribute("class", match[1]);
		}
	});
})